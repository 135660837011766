@import "../../pages/variables.scss";

.Error {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2 {
        font-size: 110px;
        letter-spacing: 20px;

        @media screen and (min-width: $mobile-small-width) {
            font-size: 150px;
        }
    }
    h3 {
        font-size: 26px;
    }

    a {
        margin-top: 30px;
        padding: 5px 15px;
    }
}
